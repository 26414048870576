<template>
  <Header />
  <div class="bg-white min-h-screen">
    <div class="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
      <h2 class="text-2xl font-extrabold tracking-tight text-gray-900">Infographics</h2>
      <!-- {{info_data}} -->
      <div class="infographic-area mt-6">
        <div v-for="product in filterList" :key="product.id" class="group relative cursor-pointer" @click="gotoLink(product.slug)">
          <div class="">
            <img :src="product.uri" :alt="product.title" class="w-full h-full" />
          </div>
          <div class="mt-4 flex flex-col justify-start">
            <div>
              <h3 class="text-lg font-bold text-gray-700">
                  <span aria-hidden="true" class="absolute inset-0" />
                  {{ product.title }}
              </h3>
              <!-- <p class="mt-1 text-sm text-gray-500">{{ product.tags }}</p> -->
            </div>
            <p class="text-sm font-medium text-gray-900">{{ relativeDate(product.created) }}</p>
          </div>
        </div>
      </div>

    </div>
  </div>
  <Footer />
</template>
<script>
import axios from 'axios'
import {rtdb} from '@/_firebase/init.js'
// import info_data from '@/assets/json/infographic-data.json'
import Header from '@/components/layouts/sections/Header.vue'
import Footer from '@/components/layouts/sections/Footer.vue'

export default {
  components:{
    Header,
    Footer
  },
  data(){
    return{
      info_data: [],
    }
  },
  methods:{
    gotoLink(info_id){
      this.$router.push({name: 'Infographic', params:{id: info_id}})
    },
    getInfographicDatabyRTDB(){
      // Get a database reference to our posts
    const ref = rtdb.ref('/infographics');
    // Attach an asynchronous callback to read the data at our posts reference
    ref.on('value', (snapshot) => {
      console.log(snapshot.val());
    }, (errorObject) => {
      console.log('The read failed: ' + errorObject.name);
    });
  },
  getInfographicData(){
    axios.get('https://api.skylineexecutive.com/infographics/all').then((res)=>{
      this.info_data = res.data.result
    }).catch((err)=>{
      console.log(err)
    })
  },
  relativeDate(epoch) {
    let simplfied_time;
    const now = new Date()
    const utc_epoch = Math.round(now.getTime() / 1000)
    let time_ago = utc_epoch - epoch
    if (time_ago < 180) { //3mins or less
      simplfied_time = `now`
    } else if (time_ago >= 180 && time_ago < 3600) {
      let min = time_ago / 60 //by mins until hour
      if (min == 1 && min < 2) {
        simplfied_time = `${min.toFixed(0)} min ago`
      } else {
        simplfied_time = `${min.toFixed(0)} mins ago`
      }
    } else if (time_ago >= 3600 && time_ago < 86400) {
      let hour = time_ago / 3600 //by mins until hour
      if (hour == 1 && hour < 2) {
        simplfied_time = `${hour.toFixed(0)} hour ago`
      } else {
        simplfied_time = `${hour.toFixed(0)} hours ago`
      }
    } else if (time_ago >= 86400 && time_ago < 604800) {
      let day = time_ago / 86400
      if (day == 1 && day < 2) {
        simplfied_time = `${day.toFixed(0)} day ago`
      } else {
        simplfied_time = `${day.toFixed(0)} days ago`
      }
    } else if (time_ago >= 604800 && time_ago < 1814400) {
      let week = time_ago / 604800
      if (week >= 1 && week < 2) {
        simplfied_time = `${week.toFixed(0)} week ago`
      } else {
        simplfied_time = `${week.toFixed(0)} weeks ago`
      }
    } else if (time_ago >= 1814400 && time_ago < 31556952) {
      let month = time_ago / 1814400
      if (month >= 1 && month < 2) {
        simplfied_time = `${month.toFixed(0)} month ago`
      } else {
        simplfied_time = `${month.toFixed(0)} months ago`
      }
    } else if (time_ago >= 31556952) {
      let year = time_ago / 31556952
      if (year == 1 && year < 2) {
        simplfied_time = `${year.toFixed(0)} year ago`
      } else {
        simplfied_time = `${year.toFixed(0)} years ago`
      }
    }
    return simplfied_time
  }
  },
  created(){
    this.getInfographicData()
  },
  computed:{
    filterList: {
      get() {
        let chrono_list = this.info_data;
        chrono_list.sort((a, b) => b.created - a.created);
        let full_list = [...chrono_list];
        let uniq = [...new Set(full_list)];
        return uniq;
      },
      set(value) {
        return value;
      },
    },
  },
}
</script>
<style scoped>
.infographic-area{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-items: stretch;
  align-items: stretch;
}
@media only screen and (min-width: 600px) {
  .infographic-area{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
}
</style>
