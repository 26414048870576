import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage'
// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyCmanSuL03iH8IPR8t12mPqqp4GuoAUw04",
  authDomain: "tycooperaow-xx99xx.firebaseapp.com",
  projectId: "tycooperaow-xx99xx",
  storageBucket: "tycooperaow-xx99xx.appspot.com",
  messagingSenderId: "624656630716",
  appId: "1:624656630716:web:eeb0e701a30c6ac9aca1b9",
  measurementId: "G-VYRKBBJQGW"
};
// Initialize Firebase
const fb = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const fs = firebase.firestore
const rtdb = firebase.database();

export { fb, db, rtdb, fs }
